<template>
  <section class="fixed flex flex-col h-screen min-w-full">
    <div class="">
      <Header bg="bg-brand" />
    </div>
    <div class="flex justify-center flex-grow">
      <Sidebar :profile="profile" />
      <div class="relative flex items-stretch flex-grow row-span-5 bg-gray-100">
        <div
          class="relative flex-grow p-3 overflow-scroll h-90vh lg:p-8 lg:pb-2"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Sidebar from "@/components/customers/Sidebar.vue";
import OneSignalVue from "onesignal-vue";

export default {
  name: "OrderHistory",
  components: {
    Header,
    Sidebar,
  },
  data() {
    return {
      date: new Date().getFullYear(),
      nalytics: {},
      statictics: {},
      user: this.$store.getters.getUser,
    };
  },
  computed: {
    profile() {
      return this.$store.getters.getUserProfile;
    },
  },
  methods: {
    async getAnalytics() {
      let url = this.$store.state.base_url + "vendor/dashboard";
      let token = await this.$store.getters.getToken;
      let res = await this.axios.get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      this.analytics = res.data;
    },
    async subscribe(context = null) {
      console.log("... Subscribing");
      this.$OneSignal.User.PushSubscription.optIn();
      const pushSubscription = this.$OneSignal.User.PushSubscription;
      if (!pushSubscription.optedIn) {
        try {
          await pushSubscription.optIn();
          if (pushSubscription.optedIn) {
            console.log(pushSubscription.id, "pushSubscription.id");
            // this.updatePlayerId(pushSubscription.id, context);
          }
        } catch (error) {
          const loginCheck = localStorage.getItem("push_notif_login_check");
          if (context && !loginCheck) {
            // this.showErrorSnackBar(
            //   "Oops!",
            //   "Dellyman cannot connect to the notification service at this time"
            // );
          }
        }
      } else if (pushSubscription.id) {
        console.log("");
        this.updatePlayerId(pushSubscription.id, context);
      }
    },
    async updatePlayerId(playerId, context = null) {
      const loginCheck = localStorage.getItem("push_notif_login_check");
      const storedPlayerId = localStorage.getItem("player_id");
      return;
      if (!storedPlayerId && playerId) {
        const token = this.$store.getters.getToken;
        try {
          const response = await this.axios.post(
            `${this.$store.state.base_url}addOneSignalPlayerId`,
            { token, playerId }
          );

          if (!response.data) {
            if (context && !loginCheck) {
              this.showErrorSnackBar(
                "Oops!",
                "Something went wrong! You won't receive push notifications. Kindly logout and login again."
              );
            }
          } else {
            localStorage.setItem("player_id", playerId);
            localStorage.setItem("push_notif_login_check", "yes");
          }
        } catch (error) {
          if (context && !loginCheck) {
            console.log(error, "error");
            // this.showErrorSnackBar(
            //   "Oops!",
            //   "Dellyman cannot connect to the notification service at this time."
            // );
          }
        }
      }
    },
    async getStatics() {
      let url = this.$store.state.base_url + "vendor/orders/statistics";
      try {
        let token = await this.$store.getters.getToken;
        let res = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        this.statictics = res.data;
      } catch (error) {}
    },
  },
  beforeMount() {
    // this.getAnalytics();
    // this.getStatics();
    this.$OneSignal.Notifications.requestPermission(true);
    console.log(
      this.$OneSignal.User,
      "this.$OneSignal.User.PushSubscription.optOut();"
    );
    this.subscribe();
  },
};
</script>

<style>
</style>